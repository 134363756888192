import React, {useEffect, useState} from "react";
import "../css/WelcomeScreen.css"
import "../css/Dashboard.css"
import "../css/Buttons.css"
import "../css/dashboard/SavedGames.css"
import LoadingScreen from "./LoadingScreen";
import {useGlobalState} from "../contexts/GlobalStateContext";
import {useNavigate} from "react-router-dom";
import {fetchSavedGames} from "../api/AccountCalls";
import {PremiumUpsellModal} from "../components/generic/PremiumUpsellModal";
import {FaHome} from "react-icons/fa";
import {loadSavedGame} from "../api/GameCalls";

export const SavedGamesScreen = ({}) => {
    const { user, isPremiumUser } = useGlobalState();
    const [showLoading, setShowLoading] = useState(false);
    const [savedGames, setSavedGames] = useState([]);
    const [hasNoSavedGames, setHasNoSavedGames] = useState(false);
    const [showPremiumUpsell, setShowPremiumUpsell] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGames = async () => {
            setShowLoading(true);
            const response = await fetchSavedGames(user?.uid);
            console.log(response)
            if (response && response.data) {
                if (response.data.length === 0) {
                    setHasNoSavedGames(true);
                } else {
                    setSavedGames(response.data.savedGames);
                }
            }
            setShowLoading(false);
        };

        if (user?.uid) {
            if (isPremiumUser) {
                fetchGames();
            } else {
                setShowPremiumUpsell(true)
            }
        }
    }, [user]);

    console.log(savedGames)

    return (
        <>
            <FaHome onClick={() => navigate('/dashboard')} className="lobby-header-icon"/>
            {!showLoading && (
                <div className="page-container-v2">
                    <div className="page-container-extra-darkener">
                        <div className="saved-games-page-container">
                            <h2 id="saved-games-header">Saved Games</h2>
                            <div className="saved-games-container">
                                {hasNoSavedGames ? (
                                    <div className="no-saved-games">
                                        <p>You don't have any saved games yet!</p>
                                    </div>
                                ) : (
                                    <div className="saved-games-list">
                                        {savedGames.map((game: any) => (
                                            <SavedGame key={game.uid} game={game} uid={user?.uid}/>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showLoading && <LoadingScreen/>}
            {showPremiumUpsell && <PremiumUpsellModal setShowPremiumUpsell={setShowPremiumUpsell} />}
        </>
    );
};

export const SavedGame = ({game, uid}) => {
    const navigate = useNavigate();

    const onLoadGame = async (gameId) => {
        const response = await loadSavedGame(uid, gameId);
        if (response) {
            navigate(`/game/${gameId}`);
        }
    };

    return (
        <div key={game.id} className="saved-game-item" onClick={() => onLoadGame(game.id)}>
            <div className="saved-game-map-container">
                <img
                    src={game.map.previewUrl}
                    alt={game.map.name}
                    className="avatar-preview-image-saved-game"
                />
            </div>
            <SavedGamePlayersList game={game} />
            <div className="saved-game-last-played-container">
                <p>Last Played</p>
                <p>{new Date(game.lastPlayed).toLocaleDateString()}</p>
            </div>
        </div>
    );
};

export const SavedGamePlayersList = ({ game }) => {
    return (
        <div className="saved-game-players-container">
            <h3>Players</h3>
            <div className="saved-games-players-list">
                {Array.from({ length: 4 }).map((_, index) => (
                    <div key={index} className="player-avatar-container">
                        {game.players[index] ? (
                            <>
                                <img
                                    src={game.players[index].character.avatar.preview_url}
                                    alt={game.players[index].character.avatar.name}
                                    className="avatar-preview-image-saved-game"
                                />
                                <p>{game.players[index].character.name}</p>
                            </>
                        ) : ( <></> )}
                    </div>
                ))}
            </div>
        </div>
    )
}