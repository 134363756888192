import axios from "axios";
import {API_URL} from "../models/Constants";

export function fetchGameUpdates(gameState) {
    const roomId = gameState.roomId;
    const playerId = gameState.currentUser;
    let endpoint = gameState.isGameEnabled ? "game/sync-game" : "new-game/sync-lobby"
    axios.get(`${API_URL}/${endpoint}/${roomId}/${playerId}`).then(response => {
        if (response) {
            console.log(response);
            if (gameState.isGameEnabled) {
                gameState.updateGameFromResponse(response.data);
            } else {
                gameState.updateLobbyFromResponse(response.data);
            }
        }
    }).catch(error => {console.error("Error fetching game state:", error);});
}

export async function createNewGame(playerId) {
    try {
        return await axios.post(`${API_URL}/new-game/create-new-game`, {playerId});
    } catch (error) {
        console.error("Error fetching game:", error);
    }
}

export async function getLobbyConfig() {
    try {
        const respone = await axios.get(`${API_URL}/new-game/lobby-config`);
        return respone.data
    } catch (error) {
        console.error("Error fetching maps:", error);
    }
}

export async function addPlayerToGame(roomId, playerId, character) {
    try {
        const respone = await axios.post(`${API_URL}/new-game/add-player`, {roomId, playerId, character});
        return respone.data
    } catch (error) {
        console.error("Error fetching maps:", error);
    }
}

export async function updateGameMap(roomId, playerId, map) {
    try {
        const respone = await axios.post(`${API_URL}/new-game/change-map`, {roomId, playerId, map});
        return respone.data
    } catch (error) {
        console.error("Error fetching maps:", error);
    }
}

export async function beginGame(roomId, playerId) {
    try {
        const respone = await axios.post(`${API_URL}/new-game/begin-game`, {roomId, playerId});
        return respone.data
    } catch (error) {
        console.error("Error fetching maps:", error);
    }
}

export async function postNewMessage(roomId, playerId, message) {
    try {
        const respone = await axios.post(`${API_URL}/game/new-message/${roomId}/${playerId}`, {message});
        return respone.data
    } catch (error) {
        console.error("Error fetching maps:", error);
    }
}

export const loadSavedGame = async (playerId, roomId) => {
    try {
        const response = await axios.get(`${API_URL}/game/load-saved-game/${playerId}/${roomId}`);
        return response;
    } catch (error) {
        console.error("Error fetching saved games:", error);
        return null;
    }
}