import React, {useState} from "react";
import "../../css/GameSetup.css";
import {WEB_URL} from "../../models/Constants";
import { FaPlus } from 'react-icons/fa';
import {shareInviteToLobby} from "../../Helper";
import {FireBasePopup} from "../../auth/FireBase";
import {LOBBY_STATES} from "../../pages/Lobby";
import {useGlobalState} from "../../contexts/GlobalStateContext";
import {addPlayerToGame} from "../../api/GameCalls";
import {PremiumUpsellModal} from "../generic/PremiumUpsellModal";

export const PlayersListLobby = ({ gameState, onDisplayAlert, setLobbyState }) => {
    const { user, isPremiumUser } = useGlobalState();
    const maxPlayers = 4;
    const [showAuthPopup, setShowAuthPopup] = useState(false);
    const [showPremiumUpsell, setShowPremiumUpsell] = React.useState(false);

    const onAddPlayerClicked = async () => {
        if (user) {
            if (!gameState.canPlayerJoin(user.uid)) {
                shareInviteToLobby(gameState.roomId, onDisplayAlert);
            } else {
                if (gameState.playerInHistory(user.uid)) {
                    let response = await addPlayerToGame(gameState.roomId, user?.uid, null)
                    gameState.updateLobbyFromResponse(response)
                } else {
                    setLobbyState(LOBBY_STATES.CHARACTER_CREATION)
                }
            }
        } else {
            setShowAuthPopup(true);
        }
    }

    const onPremiumClicked = () => {
        setShowPremiumUpsell(true);
    }

    return (
        <div className="players-list-lobby-container">
            {showPremiumUpsell && <PremiumUpsellModal setShowPremiumUpsell={setShowPremiumUpsell} />}
            {[...Array(maxPlayers)].map((_, index) => (
                gameState.players[index] ? (
                    <PlayerIcon key={index} player={gameState.players[index]} />
                ) : (
                    <PlaceholderIcon
                        key={index}
                        onAddPlayer={() => onAddPlayerClicked()}
                        onPremiumClicked={() => onPremiumClicked()}
                        canJoin={gameState.canPlayerJoin(user?.uid)}
                        premiumDisabled={!isPremiumUser && index > 1}/>
                )
            ))}
            {showAuthPopup && (
                <div className="firebase-auth-popup-container">
                    <FireBasePopup
                        setShowAuth={setShowAuthPopup}
                        successUrl={WEB_URL + "/game/" + gameState.roomId}
                    />
                </div>
            )}
        </div>
    );
};

const PlayerIcon = ({ player }) => {
    return (
        <div className="lobby-avatar">
            <div className="lobby-avatar-container">
                <img src={player.character.avatar.preview_url} alt="Current avatar" />
            </div>
            <p>{player.character.name}</p>
        </div>
    );
};

const PlaceholderIcon = ({ onAddPlayer, canJoin, premiumDisabled, onPremiumClicked }) => {
    const cssId = (premiumDisabled) ? "premium" : "placeholder"
    const onClick = (premiumDisabled) ? onPremiumClicked : onAddPlayer
    return (
        <div className="lobby-avatar" onClick={onClick}>
            <div className={`lobby-avatar-container ${cssId}`} onClick={onClick}>
                <FaPlus size={30}/>
            </div>
            {!canJoin && <p>Invite</p>}
            {canJoin && <p>Join</p>}
        </div>
    );
};