import React, {useState} from "react";
import {AvatarSelectedModal} from "./SelectedAvatar";
import {AvatarCard} from "./AvatarCard";
import "../../../css/lobby/ChooseCharacter.css";
import {addPlayerToGame} from "../../../api/GameCalls";
import {useGlobalState} from "../../../contexts/GlobalStateContext";
import {LOBBY_STATES} from "../../../pages/Lobby";

export const CharacterSelectionScreen = ({ gameState, setLobbyState, lobbyConfig }) => {
    const { user, setShowLoading, isPremiumUser } = useGlobalState();
    const [selectedAvatar, setSelectedAvatar] = useState()

    const onAvatarSelected = (avatar) => {
        setSelectedAvatar(avatar)
    }

    const addNewPlayer = async (name, skills) => {
        if (name.length > 0) {
            let character = {name: name, skills: skills, avatar: selectedAvatar}
            setShowLoading(true)
            let response = await addPlayerToGame(gameState.roomId, user?.uid, character)
            console.log(response)
            gameState.updateLobbyFromResponse(response)
            setShowLoading(false)
            setLobbyState(LOBBY_STATES.VIEW_PLAYERS)
        }
    }

    return (
        <div className="character-selection-screen">
            {selectedAvatar && <AvatarSelectedModal avatar={selectedAvatar} setSelectedAvatar={setSelectedAvatar} addNewPlayer={addNewPlayer}/>}
            <div className="character-name-avatar-container">
                <h1>Choose Your Character</h1>
                <div className="avatar-cards-list-container">
                    {lobbyConfig.avatars.map((avatar) => (
                        <AvatarCard avatar={avatar} onClick={onAvatarSelected} isPremiumUser={isPremiumUser}/>
                    ))}
                </div>
            </div>
        </div>
    );
}