import React from 'react';
import '../../css/Skills.css';
import {useNavigate} from "react-router-dom";

export const LeaveConfirmationModal = ({setShowExitModal}) => {
    const navigate = useNavigate();

    const onCancel = () => {
        setShowExitModal(false)
    }

    const confirmExit = () => {
        setShowExitModal(false);
        navigate('/dashboard');
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Leave game?</h2>
                <div className="leave-modal-buttons">
                    <button className="modal-confirm-button" onClick={() => confirmExit()}>Leave</button>
                    <button className="modal-cancel-button" onClick={() => onCancel()}>Cancel</button>
                </div>
            </div>
        </div>
    )
};
