import React, {useState} from 'react';
import '../../css/Skills.css';
import {TiThMenu} from "react-icons/ti";
import {LeaveConfirmationModal} from "./ConfirmationModal";

export const GameMenuModal = ({}) => {
    const [showGameMenu, setShowGameMenu] = useState(false);
    const [showExitModal, setShowExitModal] = useState(false);

    return (
        <div className="game-menu-header">
            <TiThMenu className="in-game-menu-icon" onClick={() => setShowGameMenu(true)}/>
            {showGameMenu &&
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Game Menu</h2>
                        <div className="game-menu-modal-buttons">
                            <button className="game-menu-modal-button primary" onClick={() => setShowGameMenu(false)}>Resume</button>
                            <button className="game-menu-modal-button secondary" onClick={() => setShowExitModal(true)}>Leave Game</button>
                        </div>
                        <p>Note: Games automatically save! You can view them in the main menu under "Saved Games"</p>
                    </div>
                </div>
            }
            {showExitModal && <LeaveConfirmationModal setShowExitModal={setShowExitModal}/>}
        </div>
    )
};
