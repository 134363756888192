import axios from "axios";
import {API_URL} from "../models/Constants";

export async function checkPremiumStatusCall(playerId) {
    try {
        return await axios.get(`${API_URL}/auth/check-premium-status/${playerId}`);
    } catch (error) {
        console.error("Error fetching game:", error);
    }
}

export const fetchSavedGames = async (playerId) => {
    try {
        const response = await axios.get(`${API_URL}/account/saved-games/${playerId}`);
        return response;
    } catch (error) {
        console.error("Error fetching saved games:", error);
        return null;
    }
};