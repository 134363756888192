// src/pages/AccountSettings.tsx
import React from "react";
import { updatePassword, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "../css/AccountSettings.css"
import {useGlobalState} from "../contexts/GlobalStateContext";
import {FaHome} from "react-icons/fa";

const AccountSettings: React.FC = () => {
    const { user, auth, isPremiumUser } = useGlobalState();
    const navigate = useNavigate();

    const handleLogout = async () => {
        await signOut(auth);
        navigate("/");
    };

    const handleChangePassword = async () => {
        const newPassword = prompt("Enter your new password:");
        if (newPassword && user) {
            try {
                await updatePassword(user, newPassword);
                alert("Password updated successfully!");
            } catch (error) {
                alert(`Error updating password: ${error}`);
            }
        }
    };

    const handleCancelMembership = async () => {
        const confirmCancel = window.confirm("Are you sure you want to cancel your premium membership?");
        if (confirmCancel && user?.uid) {
            try {
                const response = await fetch('/api/cancel-subscription', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        uid: user?.uid,  // You might need to send user ID or subscription ID
                    }),
                });

                const result = await response.json();
                if (response.ok) {
                    alert("Membership canceled successfully!");
                    // Add any additional logic to update the UI or user state
                } else {
                    alert(`Failed to cancel membership: ${result.error}`);
                }
            } catch (error) {
                console.error("Error canceling membership:", error);
                alert("An error occurred while canceling your membership.");
            }
        }
    };

    return (
        <div className="page-container-v2">
            <div className="page-container-extra-darkener">
                <FaHome onClick={() => navigate('/dashboard')} className="lobby-header-icon"/>
                <div className="account-settings-section">
                    <h2>Account Settings</h2>
                    {!user?.isAnonymous && <h3>Email: {user?.email}</h3>}
                    {user?.isAnonymous && <h3>Signed in as Guest</h3>}
                    <button onClick={handleLogout} className="cta">Logout</button>
                    {isPremiumUser &&
                        <button onClick={handleCancelMembership} className="cta">Cancel Membership</button>}
                    {!user?.isAnonymous &&
                        <button onClick={handleChangePassword} className="cta">Change Password</button>}
                </div>
            </div>
        </div>
    );
};

export default AccountSettings;