import React, {ReactNode} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SpeedInsights } from "@vercel/speed-insights/react"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

interface AppWrapperProps {
    children: ReactNode;
}

const AppWrapper: React.FC<AppWrapperProps> = ({ children }) => {
    return (
        <>
            {children}
        </>
    )
}

root.render(
    <AppWrapper>
        <SpeedInsights />
        <App />
    </AppWrapper>
);
reportWebVitals();


