import React from 'react';
import '../../css/Narration.css';
import {BiSolidLeftArrow, BiSolidRightArrow} from "react-icons/bi";

export const Narration = ({ gameState }) => {

    const leftClass = gameState.currentMessageIndex === 2 ? 'disabled-arrow' : ''
    const rightClass = gameState.currentMessageIndex === gameState.messages.length - 1 ? 'disabled-arrow' : ''

    return (
        <div className="game-narration-container">
            <p className="game-narration-content">{gameState.getCurrentNarration()}</p>
            <div className="game-narration-arrows-container">
                <BiSolidLeftArrow className={`narration-arrow ${leftClass}`} onClick={() => gameState.changeNarrationMessage(false)} />
                <BiSolidRightArrow className={`narration-arrow ${rightClass}`} onClick={() => gameState.changeNarrationMessage(true)} />
            </div>
        </div>
    );
};
