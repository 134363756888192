import React from "react";
import {FaStar} from "react-icons/fa";

export const AvatarCard = ({avatar, onClick, isPremiumUser}) => {
    return (
        <div className="avatar-card-container" onClick={() => onClick(avatar)}>
            <img className={`avatar-card-image`} src={avatar.preview_url} alt={`${avatar.name} avatar`}/>
            {avatar.premium && !isPremiumUser && <FaStar className="selection-premium-icon"/>}
        </div>
    )
}

export const AvatarCardInfoTable = ({avatar}) => {
    return (
        <table className="avatar-card-info-table">
            <tr>
                <td>Race</td>
                <td>{avatar.race}</td>
            </tr>
            <tr>
                <td>Class</td>
                <td>{avatar.class}</td>
            </tr>
        </table>
    )
}
