import React from "react";
import "../../css/PremiumUpsell.css"
import {useNavigate} from "react-router-dom";
import {useGlobalState} from "../../contexts/GlobalStateContext";
import {createCheckoutSession} from "../../auth/Stripe";
import {MdOutlineCancel} from "react-icons/md";
import MapSelection from "../../images/premium-modal/AllMapsPreview.png"
import CharacterSelection from "../../images/premium-modal/AllCharactersPreview.png"
import SavedGames from "../../images/premium-modal/SavedGamesPreview.png"
import SavedGames2 from "../../images/premium-modal/SavedGamesPreview.png"
import HostGames from "../../images/premium-modal/HostGamesPreview.png"
import {GoogleAuthProvider, linkWithPopup} from "firebase/auth";

const PREMIUM_PERKS = [
    {image: MapSelection, text: "Unlock every map"},
    {image: CharacterSelection, text: "Play any Character"},
    {image: HostGames, text: "Host games up to 4 players", subtext: "(Non premium players can join too)"},
    // {image: SavedGames, text: "Save and Continue Games"},
    {image: SavedGames2, text: "Save and Continue Games"}
]

export const PremiumUpsellModal = ({ setShowPremiumUpsell }) => {
    const { user, isPremiumUser, auth, onDisplayAlert } = useGlobalState();
    const navigate = useNavigate();

    const onTryPremium = () => {
        if (user?.uid) {
            if (user.isAnonymous) {
                const provider = new GoogleAuthProvider();  // You can change this to EmailAuthProvider if needed
                linkWithPopup(user, provider)
                    .then((result) => {
                        console.log("Anonymous account successfully linked to permanent account!");
                        createCheckoutSession(user?.uid);
                    })
                    .catch((error) => {
                        console.error("Error linking anonymous account: ", error);
                        onDisplayAlert("Something went wrong linking your account")

                    });
            } else {
                createCheckoutSession(user?.uid)
            }
        }
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <MdOutlineCancel onClick={() => setShowPremiumUpsell(false)} className="premium-upsell-modal-x"/>
                <h2 id={"premium-membership-title"}>Become a Premium Member</h2>
                <p id="premium-membership-subtitle">Premium memberships help us offset the costs of our AI processing</p>
                <div className="premium-perk-container">
                    {PREMIUM_PERKS.map((perk, index) => (
                        <PremiumPerk key={index} image={perk.image} text={perk.text} subtext={perk.subtext}/>
                    ))}
                </div>
                <button className="premium-upsell-modal-cta" onClick={() => onTryPremium()}>Try Premium</button>
            </div>
        </div>
    );
};

const PremiumPerk = ({ image, text, subtext }) => {
    return (
        <div className="premium-perk">
            <img className="premium-perk-image" src={image} alt="perk"/>
            <p className="perk-text">{text}</p>
            <p className="perk-subtext">{subtext}</p>
        </div>
    )
}