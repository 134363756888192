import React from "react";
import "../css/WelcomeScreen.css"
import "../css/Dashboard.css"
import "../css/Buttons.css"
import {useNavigate} from "react-router-dom";
import LoadingScreen from "./LoadingScreen";
import {createNewGame} from "../api/GameCalls";
import {useGlobalState} from "../contexts/GlobalStateContext";
import { FaStar } from "react-icons/fa";
import {PremiumUpsellModal} from "../components/generic/PremiumUpsellModal";
import {AboutModal} from "../components/dashboard/AboutModal";
import Logo from "../images/logo.png"

export const DashboardScreen = ({}) => {
    const { user, isPremiumUser} = useGlobalState();
    const [showLoading, setShowLoading] = React.useState(false);
    const [showAbout, setShowAbout] = React.useState(false);
    const [showPremiumUpsell, setShowPremiumUpsell] = React.useState(false);
    const navigate = useNavigate();

    const onCreateNewGame = async () => {
        setShowLoading(true);
        const response = await createNewGame(user?.uid);
        if (response) {
            setShowLoading(false);
            const roomId = response.data.roomId;
            navigate(`/game/${roomId}`);
        }
    };

    const onViewSavedGames = async () => {
        navigate("/saved-games");
    };

    const handleUserIconClick = () => {
        navigate("/account-settings");
    };

    return (
        <>
            {!showLoading && <>
                <div className="page-container-v2">
                    <div className="page-container-darkener">
                        <img className="dashboard-logo" src={Logo} />
                        <div className="dashboard-button-container">
                            <button className="dashboard-cta" onClick={onCreateNewGame}>New Game</button>
                            {!isPremiumUser &&
                            <div className="dashboard-cta secondary container" onClick={() => setShowPremiumUpsell(true)}>
                                <button className="dashboard-cta premium">Saved Games</button>
                                <FaStar className="premium-icon"/>
                            </div>}
                            {isPremiumUser &&
                                <button className="dashboard-cta secondary"
                                        onClick={onViewSavedGames}>Saved Games
                                </button>
                            }
                            <button className="dashboard-cta secondary" onClick={handleUserIconClick}>My Account
                        </button>
                        <button className="dashboard-cta secondary" onClick={() => setShowAbout(true)}>About</button>
                        </div>
                    </div>
                </div>
            </>}
            {showLoading && <LoadingScreen />}
            {showPremiumUpsell && <PremiumUpsellModal setShowPremiumUpsell={setShowPremiumUpsell} />}
            {showAbout && <AboutModal setShowAbout={setShowAbout} />}
        </>
    );
};
