import React, {useEffect, useState} from "react";
import "../css/WelcomeScreen.css"
import "../css/Lobby.css"
import "../css/ConfirmationModal.css"
import {CharacterSelectionScreen} from "../components/lobby/avatars/CharacterSelection";
import {ChosenMap, MapSelectionGrid} from "../components/gameSetup/MapPreview";
import {PlayersListLobby} from "../components/gameSetup/PlayersListLobby";
import {useGlobalState} from "../contexts/GlobalStateContext";
import { FaHome } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import {beginGame, getLobbyConfig} from "../api/GameCalls";
import LoadingScreen from "./LoadingScreen";
import {useNavigate} from "react-router-dom";
import {LeaveConfirmationModal} from "../components/generic/ConfirmationModal";

export const LOBBY_STATES = {
    MAP_SELECTION: 'map_selection',
    CHARACTER_CREATION: 'character_creation',
    VIEW_PLAYERS: 'view_players'
}

export const Lobby = ({ gameState }) => {
    const { onDisplayAlert, user } = useGlobalState();
    const [lobbyConfig, setLobbyConfig] = useState(null)
    const [lobbyState, setLobbyState] = useState(gameState.getInitialLobby())
    const [showExitModal, setShowExitModal] = useState(false);
    const navigate = useNavigate();

    const fetchLobbyConfig = async () => {
        let lobbyConfig = await getLobbyConfig()
        setLobbyConfig(lobbyConfig)
    }

    const beginGameClicked = async () => {
        let response = await beginGame(gameState.roomId, user?.uid)
        if (response) {
            gameState.updateLobbyFromResponse(response)
        }
    }

    const onHomeClicked = async () => {
        if (lobbyState === LOBBY_STATES.VIEW_PLAYERS) {
            setShowExitModal(true)
        } else {
            navigate('/dashboard');
        }
    }

    const onBackClicked = async () => {
        if (lobbyState === LOBBY_STATES.CHARACTER_CREATION) {
            if (gameState.players.length === 0) {
                setLobbyState(LOBBY_STATES.MAP_SELECTION);
            } else {
                setLobbyState(LOBBY_STATES.VIEW_PLAYERS);
            }
        } else {
            navigate(-1);
        }
    }

    useEffect(() => {
        if (!lobbyConfig) {
            fetchLobbyConfig()
        }
    }, [lobbyConfig]);

    if (!lobbyConfig) {
        return <LoadingScreen />
    }

    return (
        <div className="page-container-v2">
            <div className="page-container-extra-darkener">
                {(lobbyState === LOBBY_STATES.MAP_SELECTION || lobbyState === LOBBY_STATES.VIEW_PLAYERS)
                    && <FaHome onClick={() => onHomeClicked()} className="lobby-header-icon"/>}
                {lobbyState === LOBBY_STATES.CHARACTER_CREATION && <IoMdArrowRoundBack onClick={() => onBackClicked()} className="lobby-header-icon"/>}
                {lobbyState === LOBBY_STATES.MAP_SELECTION && <MapSelectionGrid gameState={gameState} setLobbyState={setLobbyState} lobbyConfig={lobbyConfig}/>}
                {lobbyState === LOBBY_STATES.CHARACTER_CREATION && <CharacterSelectionScreen gameState={gameState} setLobbyState={setLobbyState} lobbyConfig={lobbyConfig}/>}
                {lobbyState === LOBBY_STATES.VIEW_PLAYERS &&
                    <>
                        <ChosenMap gameState={gameState}/>
                        {gameState.players.length > 0 && <PlayersListLobby gameState={gameState} onDisplayAlert={onDisplayAlert} setLobbyState={setLobbyState} />}
                        {gameState.gameOwner === user?.uid &&
                            <button className="create-button" onClick={() => beginGameClicked()}>Start Game</button>}
                    </>}
            </div>
            {showExitModal && <LeaveConfirmationModal setShowExitModal={setShowExitModal}/>}
        </div>
    );
};