import React, {useState} from 'react';
import '../../css/Maps.css';

import {LOBBY_STATES} from "../../pages/Lobby";
import {updateGameMap} from "../../api/GameCalls";
import {useGlobalState} from "../../contexts/GlobalStateContext";
import {FaStar} from "react-icons/fa";
import {PremiumUpsellModal} from "../generic/PremiumUpsellModal";

export const MapSelectionGrid = ({ gameState, setLobbyState, lobbyConfig }) => {
    const { user, setShowLoading, isPremiumUser } = useGlobalState();
    const [showPremiumUpsell, setShowPremiumUpsell] = React.useState(false);
    const onMapSelected = async (map) => {
        if (!isPremiumUser && map.premium) {
            setShowPremiumUpsell(true)
        } else {
            setShowLoading(true)
            const response = await updateGameMap(gameState.roomId, user?.uid, map)
            if (response) {
                gameState.updateLobbyFromResponse(response)
                console.log(gameState)
                if (gameState.players.length === 0) {
                    setLobbyState(LOBBY_STATES.CHARACTER_CREATION)
                } else {
                    setLobbyState(LOBBY_STATES.VIEW_PLAYERS)
                }
            }
        }
    };

    return (
        <div className="map-selection-container">
            {showPremiumUpsell && <PremiumUpsellModal setShowPremiumUpsell={setShowPremiumUpsell} />}
            <h2>Choose Your Adventure</h2>
            <div className="map-grid">
                {lobbyConfig.maps.map((map, index) => (
                    <SingleMap key={index} map={map} onMapSelected={() => onMapSelected(map)} isPremiumUser={isPremiumUser}/>
                ))}
            </div>
        </div>
    );
};

const SingleMap = ({map, onMapSelected, isPremiumUser}) => {
    return (
        <div className="single-map" onClick={() => onMapSelected(map.name)}>
            <img src={map.previewUrl} alt={`${map.name} preview`} />
            <p>{map.name}</p>
            {map.premium && !isPremiumUser && <FaStar className="selection-premium-icon"/>}
        </div>
    );
};

export const ChosenMap = ({ gameState }) => {
    return (
        <div className="chosen-map-lobby">
            <img src={gameState.map.previewUrl} alt={`${gameState.map.name} preview`} />
            <p>{gameState.map.name}</p>
        </div>
    );
}