import React, {useEffect, useState} from "react";
import "../css/GameDisplay.css"
import {useGlobalState} from "../contexts/GlobalStateContext";
import {InGameCurrentPlayerAvatar, OtherPlayers} from "./inGame/OtherPlayers";
import {Narration} from "./inGame/Narration";
import {GameMenuModal} from "./generic/GameMenuModal";
import {postNewMessage} from "../api/GameCalls";

export const GameDisplay = ({ gameState }) => {
    const [userInput, setUserInput] = useState('');
    const [inputEnabled, setInputEnabled] = useState(false);
    const { user } = useGlobalState();

    const handleUserSubmit = async () => {
        setInputEnabled(false)
        const roll = Math.floor(Math.random() * 20) + 1;
        const userRefString = gameState.players.length === 1 ? "You" : gameState.getCurrentPlayer().character.name
        const theyRefString = gameState.players.length === 1 ? "You" : "They"
        const userMessage = userRefString + " said: " + userInput + "\n" + theyRefString + " rolled a " + roll
        let newMessage = {"role": "user", "content": userMessage, "roll": roll}
        setUserInput('');
        gameState.onNewGameMessage(newMessage)
        let response = postNewMessage(gameState.roomId, gameState.currentUser, newMessage)
    };

    useEffect(() => {
        if (gameState.players.length > 1) {
            setInputEnabled(gameState.currentTurn === user?.uid);
        } else {
            // For single-player games, re-enable input after the next message is received
            if (gameState.messages.length > 2 && gameState.messages[gameState.messages.length - 1]?.role === 'assistant') {
                setInputEnabled(true);
            }
        }
    }, [gameState, gameState.currentTurn, gameState.messages, user]);

    return (
        <div className="game-container" style={{backgroundImage: "url(" + gameState.map.url + ")"}}>
            <GameMenuModal />
            <Narration gameState={gameState} />
            <div className="user-container">
                {gameState.players.length > 1 && <OtherPlayers gameState={gameState}/>}
                <InGameCurrentPlayerAvatar gameState={gameState}/>
                <div className="user-input-container">
                    <input
                        type="text"
                        placeholder="Your response here..."
                        className="user-input"
                        value={userInput}
                        disabled={!inputEnabled}
                        onChange={(e) => setUserInput(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleUserSubmit()
                            }
                        }
                        }
                    />
                    <button className="user-submit-text-btn"
                            disabled={!inputEnabled}
                            onClick={handleUserSubmit}>Submit
                    </button>
                </div>
            </div>
        </div>
    );
};