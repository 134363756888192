import React from 'react';
import { FaBrain } from "react-icons/fa";
import { GiStrong } from "react-icons/gi";
import { MdCasino } from "react-icons/md";
import { BsStars } from "react-icons/bs";
import { GrMagic } from "react-icons/gr";
import { MdVisibilityOff } from "react-icons/md";
import '../../css/Skills.css';

export type SkillName = 'Strength' | 'Intelligence' | 'Magic' | 'Charisma' | 'Luck' | 'Stealth';

export const getSkillIcon = (skillName: SkillName) => {
    switch (skillName) {
        case "Strength": return <GiStrong />;
        case "Intelligence": return <FaBrain />;
        case "Magic": return <GrMagic />;
        case "Charisma": return <BsStars />;
        case "Luck": return <MdCasino />;
        case "Stealth": return <MdVisibilityOff />;
        default: return null;
    }
};