import React, {useState} from "react";
import {AvatarCardInfoTable} from "./AvatarCard";
import {getSkillIcon} from "../../gameSetup/SkillDisplay";
import {BiSolidLeftArrow, BiSolidRightArrow} from "react-icons/bi";
import { MdOutlineCancel, MdOutlineEdit } from "react-icons/md";
import {PremiumUpsellModal} from "../../generic/PremiumUpsellModal";
import {FaStar} from "react-icons/fa";
import {useGlobalState} from "../../../contexts/GlobalStateContext";

export const AvatarSelectedModal = ({avatar, setSelectedAvatar, addNewPlayer}) => {
    const { isPremiumUser} = useGlobalState();
    const [skills, setSkills] = useState(avatar.skills);
    const [nameInput, setNameInput] = useState('');
    const [showPremiumUpsell, setShowPremiumUpsell] = React.useState(false);

    const onCreateButton = () => {
        if (!isPremiumUser && avatar.premium) {
            setShowPremiumUpsell(true)
        } else {
            addNewPlayer(nameInput, skills)
        }
    }

    const showPremiumButton = !isPremiumUser && avatar.premium

    return (
        <div className="avatar-selected-modal-container">
            <div className="avatar-selected-modal-content">
                {showPremiumUpsell && <PremiumUpsellModal setShowPremiumUpsell={setShowPremiumUpsell} />}
                <div className="avatar-selected-modal-left">
                    <div className="avatar-card-image-selected-container">
                        <img className={`avatar-card-image-selected`} src={avatar.preview_url} alt={`${avatar.name} avatar`}/>
                        {showPremiumButton && <FaStar className="premium-icon"/>}
                    </div>
                    <AvatarCardInfoTable avatar={avatar}/>
                </div>
                <div className="avatar-selected-modal-right">
                    <MdOutlineCancel onClick={() => setSelectedAvatar(null)} className="avatar-selected-modal-x"/>
                    <AvatarSelectedNameInput nameInput={nameInput} setNameInput={setNameInput} />
                    <AvatarSelectedSkillsList skills={skills} setSkills={setSkills}/>
                    {showPremiumButton &&
                        <div className="create-character-button-premium-container" onClick={() => setShowPremiumUpsell(true)}>
                            <p>Create</p>
                            <FaStar className="premium-icon"/>
                        </div>
                    }
                    {!showPremiumButton &&
                        <button className="create-character-button" onClick={() => onCreateButton()}>Create</button>}
                </div>
            </div>
        </div>
    )
}

export const AvatarSelectedNameInput = ({ nameInput, setNameInput }) => {
    return (
        <div className="character-name-container">
            <div className="name-input-container">
                <p>Name:</p>
                <input
                    type="text"
                    className="name-input"
                    value={nameInput}
                    onChange={(e) => setNameInput(e.target.value)}
                />
            </div>
        </div>
    )
}

export const AvatarSelectedSkillsList = ({ skills, setSkills }) => {
    const [remainingPoints, setRemainingPoints] = useState<number>(0);
    const [isEditing, setIsEditing] = useState(false);
    const handlePointChange = (index: number, isIncrease: boolean) => {
        let newValue
        const updatedSkills = [...skills];
        if (isIncrease) {
            if (remainingPoints === 0 || skills[index].value === 10) return
            newValue = skills[index].value + 1
            setRemainingPoints((prevPoints) => prevPoints - 1);
        } else {
            if (skills[index].value === 0) return
            newValue = skills[index].value - 1
            setRemainingPoints((prevPoints) => prevPoints + 1);
        }
        updatedSkills[index] = { ...updatedSkills[index], value: newValue };
        setSkills(updatedSkills);
    };

    const onEditClicked = () => {
        setIsEditing(!isEditing);
    }

    return (
        <div className="avatar-selected-skills-container">
            <MdOutlineEdit className="skills-customize-icon" onClick={() => onEditClicked()}/>
            <h3>Attributes</h3>
            {isEditing && <p id="skills-edit-remaining-points">Remaining Points: {remainingPoints}</p>}
            <div className="avatar-selected-skills-list">
                {skills.map((skill, index) => (
                    <SingleSkill skill={skill} index={index} handlePointChange={handlePointChange} isEditing={isEditing}/>
                ))}
            </div>
        </div>
    )
}

export const SingleSkill = ({skill, index, handlePointChange, isEditing}) => {
    return (
        <div className="skill chosen-avatar-skill">
            {getSkillIcon(skill.name)}
            <span className="skill-name">{skill.name} </span>
            <div className="set-skill-value-container">
                {isEditing && <BiSolidLeftArrow className="arrow" onClick={() => handlePointChange(index, false)}/>}
                <span className="skill-value">{skill.value}</span>
                {isEditing && <BiSolidRightArrow className="arrow" onClick={() => handlePointChange(index, true)} />}
            </div>
        </div>
    );
};