import "../../css/dashboard/AboutModal.css"
import {MdOutlineCancel} from "react-icons/md";
import React from "react";

export const AboutModal = ({ setShowAbout }) => {
    return (
        <div className="modal-overlay" onClick={() => setShowAbout(false)}>
            <div className="modal-content" onClick={(event) => event.stopPropagation()}>
                <MdOutlineCancel onClick={() => setShowAbout(false)} className="premium-upsell-modal-x"/>
                <div className="about-modal">
                    <h3>About</h3>
                    <p>Endless Realms is centered around bringing the classic "Text Based Adventure" games to the modern age.<br/>Using new AI models, you are no longer bound to the traditional responses of older text games.<br/>Respond any way you want, and see where the game takes you. Explore the endless realms you can create!</p>
                    <p className="legal-info-p-item">PS: I'm a single developer working on this project. If you need anything or have questions, feel free to reach out to
                        me at <br/>davidthurmanwork@gmail.com</p>
                    <p>If you enjoy this, you can also check out my other AI games at https://aibraingames.io</p>
                    <p className="legal-info-p-item" id="legal-info-disclaimer">Disclaimer: Games are generated by AI
                        and
                        results can't be verified</p>
                    <div className="legal-info-item-container">
                        <p className="legal-info-p-copyright">© 2024 AI Brain Games. All Rights Reserved.</p>
                        <p className="legal-info-item-dot">•</p>
                        <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=e385d701-3f62-40f9-b661-b485d5ca188a"
                           className="legal-info-item">Terms of Use</a>
                        <p className="legal-info-item-dot">•</p>
                        <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=50078d71-a78f-496b-a633-712d0df91b8b"
                           className="legal-info-item">Privacy Policy</a>
                    </div>
                </div>
            </div>
        </div>
    )
}