//src/auth/Stripe.ts
import { loadStripe } from "@stripe/stripe-js";
import {API_URL} from "../models/Constants";

/*
    Stripe Config Rundown

    Dependencies:
    - npm install @stripe/stripe-js --legacy-peer-deps

    Stripe works w/ Firebase via a Webhook and a Firebase Extension
    Firebase -> Stripe Extension -> How this extension works: has the function URL for Webhooks
    Firebase -> Stripe Extension -> Extension Configuration: has all table info, API key, & webhook key setup
    Firebase -> FireStore Database -> Rules: sets read/write access for DB. Make sure Stripe has write access or it'll fail
 */

// Toggle live or test mode
// const IS_STRIPE_LIVE = false;
// Other requirements for test mode:
// - In Stripe, toggle Test Mode
// - Stripe -> Developers - API Keys -> Replace the API Keys in Firebase
// - Stripe -> Developers - Webhooks -> Replace the Webhook Key in Firebase

const STRIPE_KEY = "pk_live_51PsEnxLxzEG1Bif5824OSTN4UePhWEctuq8FYBLPjITZBVJFpYKILmtFGgg9yqVlPlfjsTkk7HCiix4dwNdes4ye00eK5nVQBF"

const stripePromise = loadStripe(STRIPE_KEY);

export async function createCheckoutSession(uid) {

    const stripe = await stripePromise;

    const response = await fetch(`${API_URL}/stripe/create-checkout-session`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            uid,
            success_url: window.location.origin + '/',
            cancel_url: window.location.origin + '/',
        }),
    });

    const session = await response.json();

    if (session.error || !stripe) {
        console.error(session.error);
    } else {
        // Redirect to Stripe Checkout using the session ID
        const { error } = await stripe.redirectToCheckout({
            sessionId: session.sessionId,
        });

        if (error) {
            console.error("Stripe Checkout error:", error.message);
        }
    }
}
