import {WEB_URL} from "./models/Constants";

export const getUsername = () => {
    let storedUsername = getCookie("username");
    if (!storedUsername) {
        storedUsername = generateRandomUsername();
        setCookie("username", storedUsername, 30); // Store for 30 days
    }
    return storedUsername
}

function setCookie(name, value, days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

const generateRandomUsername = () => {
    const adjectives = ["Brave", "Mighty", "Swift", "Silent", "Clever", "Wise", "Fierce", "Loyal"];
    const nouns = ["Lion", "Tiger", "Eagle", "Wolf", "Bear", "Hawk", "Shark", "Panther"];
    const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
    const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
    const randomNumber = Math.floor(Math.random() * 1000);

    return `${randomAdjective}${randomNoun}${randomNumber}`;
};

function isMobileDevice() {
    return /Mobi|Android/i.test(navigator.userAgent);
}

export const shareInviteToLobby = (roomId, onDisplayAlert) => {
    const shareData = {
        title: "",
        text: WEB_URL + "/game/" + roomId
    }
    if (navigator.share && isMobileDevice()) {
        navigator.share(shareData)
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
    } else if (navigator.clipboard) {
        navigator.clipboard.writeText(shareData.text)
            .then(() => onDisplayAlert("Copied to clipboard!"))
            .catch((error) => console.log('Error copying to clipboard', error));
    } else {
        onDisplayAlert("Share not supported");
    }
};
