import React, {createContext, useState, useContext, useReducer, ReactNode, useEffect} from 'react';
import {getAuth, Auth, User} from "firebase/auth";

interface GlobalFunctions {
    setShowLoading: (loading: boolean) => void;
    setShowPopupModal: (showPopupModal: boolean) => void;
    onDisplayAlert: (message: string) => void;
    setShowKeyboard: (showKeyboard: boolean) => void;
    forceUpdate: () => void;
}

interface GlobalState extends GlobalFunctions {
    showAlert: boolean;
    showLoading: boolean;
    showPopupModal: boolean;
    showKeyboard: boolean;
    alertText: string;
    forceUpdate: () => void;
    isMobile: boolean;
    auth: Auth;
    user: User | null;
    isPremiumUser: boolean;
}

const GlobalStateContext = createContext<GlobalState | undefined>(undefined);

export const useGlobalState = (): GlobalState => {
    const context = useContext(GlobalStateContext);
    if (!context) {
        throw new Error("useGlobalState must be used within a GlobalStateProvider");
    }
    return context;
};

export const useForceUpdate = () => {
    return useReducer(() => ({}), {})[1] as () => void;
};

interface GlobalStateProviderProps {
    children: ReactNode;
    user: User | null;
    isPremiumUser: boolean;
}

export const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({ children, user, isPremiumUser }) => {
    const [showAlert, setShowAlert] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showKeyboard, setShowKeyboard] = useState(false);
    const [showPopupModal, setShowPopupModal] = useState(false);
    const [alertText, setAlertText] = useState('');
    const forceUpdate = useForceUpdate();
    const auth = getAuth();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1100);

    const onDisplayAlert = (text: string) => {
        setAlertText(text);
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
            setAlertText('');
        }, 2000);
    };

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 1100);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const globalFunctions: GlobalFunctions = {
        setShowLoading,
        setShowPopupModal,
        setShowKeyboard,
        onDisplayAlert,
        forceUpdate
    };

    return (
        <GlobalStateContext.Provider value={{
            ...globalFunctions,
            showKeyboard,
            showPopupModal,
            showAlert,
            showLoading,
            alertText,
            isMobile,
            auth,
            user,
            isPremiumUser
        }}>
            {children}
        </GlobalStateContext.Provider>
    );
};