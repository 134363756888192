import React, {useEffect, useState} from "react";
import "../css/WelcomeScreen.css"
import {useGlobalState} from "../contexts/GlobalStateContext";
import {fetchGameUpdates} from "../api/GameCalls";
import {useParams} from "react-router-dom";
import {GameState} from "../models/GameState";
import {Lobby} from "./Lobby";
import {GameDisplay} from "../components/GameDisplay";
import LoadingScreen from "./LoadingScreen";

export const GameView = ({}) => {
    const { roomId } = useParams();
    const { forceUpdate, showAlert, alertText, user } = useGlobalState();
    const [gameState, setGameState] = useState<GameState | null>(null);

    useEffect(() => {
        if (user?.uid) {
            let newGameState = new GameState(forceUpdate, roomId, user?.uid)
            setGameState(newGameState)
            fetchGameUpdates(newGameState);
            const interval = setInterval(() => fetchGameUpdates(newGameState), 10000);
            return () => clearInterval(interval);
        }
    }, [user, roomId, forceUpdate]);

    if (!gameState) {
        return (<LoadingScreen />)
    }

    return (
        <>
            {!gameState.isGameEnabled && !gameState.showLoadingScreen && <Lobby gameState={gameState} />}
            {gameState.isGameEnabled && !gameState.showLoadingScreen && <GameDisplay gameState={gameState} />}
            {gameState.showLoadingScreen && <LoadingScreen />}
            {showAlert && <div className="clipboard-alert">{alertText}</div>}
        </>
    );
};