import React from 'react';
import "../css/GameSetup.css"

const LoadingScreen = () => {
    return (
        <div className="page-container-v2">
            <div className="page-container-darkener">
                <div className="loading-text">Loading...</div>
            </div>
        </div>
    );
};

export default LoadingScreen;