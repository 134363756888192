import {LOBBY_STATES} from "../pages/Lobby";

export type ForceUpdate = () => void;

export class GameState {

    players: any = []
    playersHistory: any = []
    gameOwner: string = ""
    currentUser: string = ""
    messages: any = []
    map: any = ""
    roomId: string = ""
    isGameEnabled: boolean = false;
    showLoadingScreen: boolean = false;
    currentTurn: string = ""
    currentMessageIndex = 2
    forceUpdate: () => void;

    constructor(forceUpdate: ForceUpdate, roomId, currentUser) {
        this.forceUpdate = forceUpdate;
        this.roomId = roomId
        this.currentUser = currentUser
        this.players = []
        this.playersHistory = []
        this.messages = []
        this.showLoadingScreen = true
    }

    public onNewGameMessage(newMessage) {
        this.messages.push(newMessage)
        this.currentMessageIndex ++
        this.forceUpdate()
    }

    public getCurrentPlayer() {
        for (let player of this.players) {
            if (player.userId === this.currentUser) {
                return player
            }
        }
    }

    public canPlayerJoin(uid) {
        for (let player of this.players) {
            if (player.userId === uid) {
                return false
            }
        }
        return true
    }

    public playerInHistory(uid) {
        for (let player of this.playersHistory) {
            if (player.userId === uid) {
                return true
            }
        }
        return false
    }

    public getCurrentNarration() {
        if (this.messages[this.currentMessageIndex]) {
            return this.messages[this.currentMessageIndex].content
        }
        return ""
    }

    public changeNarrationMessage(wasNext: boolean) {
        if (wasNext) {
            if (this.currentMessageIndex === this.messages.length - 1) return
            this.currentMessageIndex++
        } else {
            if (this.currentMessageIndex === 2) return
            this.currentMessageIndex--
        }
        this.forceUpdate()
    }

    public updateLobbyFromResponse(response) {
        this.showLoadingScreen = false
        if (response.map) {
            this.map = response.map
        }
        if (response.players) {
            this.players = response.players
        }
        if (response.playersHistory) {
            this.playersHistory = response.playersHistory
        }
        if (response.isGameEnabled) {
            this.isGameEnabled = response.isGameEnabled
            this.showLoadingScreen = true
        }
        if (response.owner) {
            this.gameOwner = response.owner
        }
        this.forceUpdate()
    }

    public updateGameFromResponse(response) {
        console.log(response)
        if (response.messages.length > 2) {
            this.showLoadingScreen = false
        }
        this.map = response.map
        this.players = response.players
        this.isGameEnabled = response.isGameEnabled
        this.gameOwner = response.owner
        if (response.messages.length > this.messages.length) {
            this.messages = response.messages
            this.currentMessageIndex = this.messages.length - 1
        }
        this.currentTurn = response.currentTurn
        this.forceUpdate()
    }

    public getInitialLobby() {
        if (!this.map || this.map.length === 0) {
            return LOBBY_STATES.MAP_SELECTION
        }
        return LOBBY_STATES.VIEW_PLAYERS
    }
}