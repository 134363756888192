import React from 'react';
import '../../css/Avatar.css';
import {getSkillIcon} from "../gameSetup/SkillDisplay";

export const OtherPlayers = ({ gameState }) => {
    const otherPlayers = gameState.players.filter(player => player.userId !== gameState.currentUser);
    return (
        <div className="other-players-container">
            {otherPlayers.map(player => (
                <InGamePlayerIcon key={player.name} gameState={gameState} player={player} />
            ))}
        </div>
    );
};

export const InGamePlayerIcon = ({ gameState, player }) => {
    let isPlayersTurn = gameState.currentTurn === player.username
    return (
        <div className={`other-player-container`}>
            <div className="other-player-avatar-icon-container">
                <img src={player.character.avatar.preview_url} alt={`${player.character.name}'s avatar`}
                     className={`player-avatar ${isPlayersTurn ? 'highlight-player' : ''}`}/>
                <p className="player-name">{player.character.name}</p>
            </div>
            <PlayerStats player={player} isPlayersTurn={isPlayersTurn} />
        </div>
    );
}

const PlayerStats = ({ player, isPlayersTurn }) => {
    return (
        <div className={`other-player-stats ${isPlayersTurn ? 'highlight-stats' : ''}`}>
            {player.character.skills.map((skill, index) => (
                <div key={index} className="stat-item">
                    {getSkillIcon(skill.name)}
                    <span className="stat-value">{skill.value}</span>
                </div>
            ))}
        </div>
    )
}

export const InGameCurrentPlayerAvatar = ({gameState}) => {
    let isPlayersTurn = gameState.currentTurn === gameState.currentUser
    let player = gameState.getCurrentPlayer()
    return (
        <div className={`current-player-container`}>
            <div className="current-player-avatar-icon-container">
                <img src={player.character.avatar.avatar_url} alt={`${player.character.name}'s avatar`}
                     className={`in-game-current-avatar`}/>
            </div>
            <InGameCurrentPlayerStats player={player} isPlayersTurn={isPlayersTurn} />
        </div>
    );
}

const InGameCurrentPlayerStats = ({ player, isPlayersTurn }) => {
    return (
        <div className={`current-player-stats ${isPlayersTurn ? 'highlight-stats' : ''}`}>
            {player.character.skills.map((skill, index) => (
                <div key={index} className="current-player-stat-item">
                    {getSkillIcon(skill.name)}
                    <span className="stat-value">{skill.value}</span>
                </div>
            ))}
        </div>
    )
}